define('ecommerce-portal/pods/dashboard/controller', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
	exports['default'] = _ember['default'].Controller.extend({
		config: _ember['default'].inject.service('config'),
		ajax: _ember['default'].inject.service(),
		session: _ember['default'].inject.service('session'),
		loadingSpinner: _ember['default'].inject.service('loading'),
		notify: _ember['default'].inject.service(),

		actions: {
			drawCharts: function drawCharts(chartResponse) {
				this.set("showLockerChart", false);
				this.set("rtcChart", false);
				this.set("complianceChart", false);
				this.set("outboundBarChartBool", false);
				this.set("inboundBarChartBool", false);
				this.set("horizontalBarBool", false);

				var scaleFontColor = 'black';
				var labelSettings = {
					boxWidth: 10,
					fontColor: '#000'
				};
				var legendSettings = {
					position: 'top',
					labels: labelSettings,
					onClick: function onClick(e, legendItem) {
						var index = legendItem.datasetIndex;
						var ci = this.chart;
						var meta = ci.getDatasetMeta(index),
						    hiddenCount = 0;

						ci.data.datasets.forEach(function (eachDataset, i) {
							if (ci.getDatasetMeta(i).hidden && index != i) {
								hiddenCount++;
							}
						});

						if (hiddenCount == ci.data.datasets.length - 1) {
							e.preventDefault();
							e.stopPropagation();
							return;
						}

						meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
						ci.update();
					}
				};

				var colorsArr = [{
					fill: '#fbb77b',
					stroke: '#fdcea2'
				}, {
					fill: '#a3c0c6',
					stroke: '#7ca9b2'
				}, {
					fill: '#ffe7a7',
					stroke: '#ffdd7f'
				}, {
					fill: '#fcc49d',
					stroke: '#f4762a'
				}, {
					fill: '#e0eadf',
					stroke: '#9fc198'
				}, {
					fill: '#ffdd7f',
					stroke: '#ffcb05'
				}, {
					fill: '#fdcea2',
					stroke: '#f9a155'
				}];

				var controller = this;
				var checkoutArr = [0, 0, 0, 0, 0];
				var dropOffArr = [0, 0, 0, 0, 0];
				var lockerArr = [0, 0, 0, 0, 0];
				var rtcArr = [0, 0, 0, 0, 0];
				var manualArr = [0, 0, 0, 0, 0];

				var lockerCountNum = 0;
				var inboundCheckoutCountNum = 0;
				var rtcCountNum = 0;
				var outboundCountNum = 0;

				var horizontalBarChartDataSet = chartResponse.product_volume;
				if (horizontalBarChartDataSet && horizontalBarChartDataSet.length > 0) {
					for (var i = 0; i < horizontalBarChartDataSet.length; i++) {
						var stateArr = horizontalBarChartDataSet[i].state;
						if (horizontalBarChartDataSet[i].product === "Inbound") {
							for (var j = 0; j < stateArr.length; j++) {
								if (stateArr[j].status === "Checkout") {
									inboundCheckoutCountNum += stateArr[j].volume;
									checkoutArr[3] = stateArr[j].volume;
								}
								if (stateArr[j].status === "Manual Checkout") {
									inboundCheckoutCountNum += stateArr[j].volume;
									manualArr[3] = stateArr[j].volume;
								}
								if (stateArr[j].status === "Return to courier") {
									rtcCountNum += stateArr[j].volume;
									rtcArr[3] = stateArr[j].volume;
								}
								if (stateArr[j].status === "Locker Checkout") {
									lockerCountNum += stateArr[j].volume;
									lockerArr[3] = stateArr[j].volume;
								}
							}
						} else if (horizontalBarChartDataSet[i].product === "Click and Collect") {
							for (var c = 0; c < stateArr.length; c++) {
								if (stateArr[c].status === "Checkout") {
									inboundCheckoutCountNum += stateArr[c].volume;
									checkoutArr[0] = stateArr[c].volume;
								}
								if (stateArr[c].status === "Manual Checkout") {
									inboundCheckoutCountNum += stateArr[c].volume;
									manualArr[0] = stateArr[c].volume;
								}
								if (stateArr[c].status === "Return to courier") {
									rtcCountNum += stateArr[c].volume;
									rtcArr[0] = stateArr[c].volume;
								}
								if (stateArr[c].status === "Locker Checkout") {
									lockerCountNum += stateArr[c].volume;
									lockerArr[0] = stateArr[c].volume;
								}
							}
						} else if (horizontalBarChartDataSet[i].product === "return" || horizontalBarChartDataSet[i].product === "outbound") {
							for (var d = 0; d < stateArr.length; d++) {
								outboundCountNum += stateArr[d].volume;
								dropOffArr[4] += stateArr[d].volume;
							}
						}
						// else if(horizontalBarChartDataSet[i].product==="Consumer Portal DropOff") {
						// 	for(var d=0; d<stateArr.length; d++){
						// 		outboundCountNum += stateArr[d].volume;
						// 		dropOffArr[1] += stateArr[d].volume;
						// 	}
						// }
						// else if(horizontalBarChartDataSet[i].product==="CP External DropOff") {
						// 	for(var d=0; d<stateArr.length; d++){
						// 		outboundCountNum += stateArr[d].volume;
						// 		dropOffArr[2] += stateArr[d].volume;
						// 	}
						// }
					}
				}
				if (chartResponse.checked_in_inbound_parcels && chartResponse.checked_in_inbound_parcels !== "") {
					var checkInInboundCountNum = chartResponse.checked_in_inbound_parcels;
					controller.set('checkInInboundCountNum', checkInInboundCountNum > 1000 ? checkInInboundCountNum / 1000 + 'K' : checkInInboundCountNum);
				}
				controller.set('lockerCountNum', lockerCountNum > 1000 ? lockerCountNum / 1000 + 'K' : lockerCountNum);
				controller.set('rtcCountNum', rtcCountNum > 1000 ? rtcCountNum / 1000 + 'K' : rtcCountNum);
				controller.set('inboundCheckoutCountNum', inboundCheckoutCountNum > 1000 ? inboundCheckoutCountNum / 1000 + 'K' : inboundCheckoutCountNum);
				controller.set('outboundCountNum', outboundCountNum > 1000 ? outboundCountNum / 1000 + 'K' : outboundCountNum);

				var horizontalBarChartData = {
					labels: ['Checkout', 'DropOff', 'Locker Checkout', 'Return to Courier', 'Manual Checkout'],
					datasets: [{
						label: 'Click & Collect',
						backgroundColor: '#f9a976',
						borderColor: '#f9a976',
						borderWidth: 1,
						data: [checkoutArr[0], dropOffArr[0], lockerArr[0], rtcArr[0], manualArr[0]]
					},
					//  {
					// 	label: 'Consumer Portal DropOff',
					// 	backgroundColor: '#fcc49d',
					// 	borderColor: '#fcc49d',
					// 	borderWidth: 1,
					// 	data: [ checkoutArr[1], dropOffArr[1], lockerArr[1], rtcArr[1], manualArr[1] ]
					// },
					//  {
					// 	label: 'CP External DropOff',
					// 	backgroundColor: '#a3c0c6',
					// 	borderColor: '#a3c0c6',
					// 	borderWidth: 1,
					// 	data: [ checkoutArr[2], dropOffArr[2], lockerArr[2], rtcArr[2], manualArr[2] ]
					// },
					{
						label: 'Inbound',
						backgroundColor: '#f9a155',
						borderColor: '#f9a155',
						borderWidth: 1,
						data: [checkoutArr[3], dropOffArr[3], lockerArr[3], rtcArr[3], manualArr[3]]
					}, {
						label: 'Outbound',
						backgroundColor: '#53969f',
						borderColor: '#53969f',
						borderWidth: 1,
						data: [checkoutArr[4], dropOffArr[4], lockerArr[4], rtcArr[4], manualArr[4]]
					}]

				};
				var horizontalBarChartOptions = {
					elements: {
						rectangle: {
							borderWidth: 2
						}
					},
					responsive: true,
					legend: legendSettings,
					scales: {
						xAxes: [{ stacked: true, ticks: {
								beginAtZero: true
							} }],
						yAxes: [{ stacked: true, ticks: {
								beginAtZero: true
							} }]
					}
				};
				controller.set("horizontalBarChartData", horizontalBarChartData);
				controller.set("horizontalBarChartOptions", horizontalBarChartOptions);
				this.set("horizontalBarBool", true);

				if (this.get('session.data.authenticated.userDetails.company_name') === '7-Eleven' || this.get('session.data.authenticated.userDetails.partnerName') === '7-Eleven') {
					var channelArr = ['7-Eleven', 'Others'];
					var inboundVolumeArr = [];
					var others_inboundVolumeArr = [];
					var outboundVolumeArr = [];
					var others_outboundVolumeArr = [];
					var errorVolumeArr = [];
					var others_errorVolumeArr = [];
					var noErrorVolumeArr = [];
					var others_noErrorVolumeArr = [];

					var inboundBarChartDataSet = chartResponse.channel_volume;
					if (inboundBarChartDataSet && inboundBarChartDataSet.length > 0) {
						for (var i = 0; i < inboundBarChartDataSet.length; i++) {
							var product_volume = inboundBarChartDataSet[i].product_volume;
							var tempVolume = 0;
							var outboundProductVolume = 0;
							var errorVolume = 0;
							var noErrorVolume = 0;
							for (var pv = 0; pv < product_volume.length; pv++) {
								// tempVolume += product_volume[pv].volume;
								errorVolume += product_volume[pv].compliance_error_yes;
								noErrorVolume += product_volume[pv].compliance_error_no;
								if (product_volume[pv].product === "return" || product_volume[pv].product === "outbound") {
									outboundProductVolume += product_volume[pv].volume;
								}
								if (product_volume[pv].product === "Click and Collect" || product_volume[pv].product === "Inbound") {
									tempVolume += product_volume[pv].volume;
								}
							}
							if (inboundBarChartDataSet[i].channel === "7-Eleven") {
								errorVolumeArr = errorVolume;
								noErrorVolumeArr = noErrorVolume;
								inboundVolumeArr = tempVolume;
								outboundVolumeArr = outboundProductVolume;
							} else {
								others_errorVolumeArr += errorVolume;
								others_noErrorVolumeArr += noErrorVolume;
								others_inboundVolumeArr += tempVolume;
								others_outboundVolumeArr += outboundProductVolume;
							}
						}
					}

					var inboundBarChartData = {
						labels: channelArr,
						datasets: [{
							label: this.get('session.data.authenticated.userDetails.courierName'),
							backgroundColor: '#fcc49d',
							stack: 'Stack 0',
							data: [inboundVolumeArr, others_inboundVolumeArr]
						}]
					};
					var complianceErrorData = {
						labels: channelArr,
						datasets: [{
							label: 'Error',
							backgroundColor: '#fbb77b',
							borderColor: '#fbb77b',
							fill: false,
							data: [errorVolumeArr, others_errorVolumeArr]
						}, {
							label: 'No Error',
							backgroundColor: '#53969f',
							borderColor: '#53969f',
							fill: false,
							data: [noErrorVolumeArr, others_noErrorVolumeArr]
						}]
					};
					var outboundBarChartData = {
						labels: channelArr,
						datasets: [{
							label: 'Increase',
							backgroundColor: '#a3c0c6',
							stack: 'Stack 0',
							data: [outboundVolumeArr, others_outboundVolumeArr]
						}]
					};

					var rtcChannelArr = channelArr;
					var stateArr = [];
					var stateKeyValueArr = [];

					var rtcDataSetArr = chartResponse.state_volume;

					var stateCount = 0;

					if (rtcDataSetArr && rtcDataSetArr.length > 0) {
						for (var i = 0; i < rtcDataSetArr.length; i++) {
							var stateVolumeArr = rtcDataSetArr[i].state_volume;
							for (var s = 0; s < stateVolumeArr.length; s++) {
								var foundIndexVal = stateArr.indexOf(stateVolumeArr[s].state);
								if (foundIndexVal === -1) {
									stateCount++;
									stateArr.push(stateVolumeArr[s].state);
									var tempArr = [0, 0];
									if (rtcDataSetArr[i].channel === "7-Eleven") {
										tempArr[0] += stateVolumeArr[s].volume;
									} else {
										tempArr[1] += stateVolumeArr[s].volume;
									}
									var tempObj = {
										label: stateVolumeArr[s].state,
										fill: true,
										backgroundColor: colorsArr[stateCount].fill,
										pointBackgroundColor: colorsArr[stateCount].stroke,
										borderColor: colorsArr[stateCount].stroke,
										pointHighlightStroke: colorsArr[stateCount].stroke,
										borderCapStyle: 'butt',
										data: tempArr
									};
									stateKeyValueArr.push(tempObj);
								} else {
									var tempArr = stateKeyValueArr[foundIndexVal]['data'];
									if (rtcDataSetArr[i].channel === "7-Eleven") {
										tempArr[0] += stateVolumeArr[s].volume;
									} else {
										tempArr[1] += stateVolumeArr[s].volume;
									}
									stateKeyValueArr[foundIndexVal]['data'].push(tempArr);
								}
							}
						}
					}
					//end of 7-11 data design
				} else {
						//to all others users
						var channelArr = [];
						var inboundChannelArr = [];
						var outboundChannelArr = [];
						var volumeArr = [];
						var outboundVolumeArr = [];
						var errorVolumeArr = [];
						var noErrorVolumeArr = [];

						var inboundBarChartDataSet = chartResponse.channel_volume;

						if (inboundBarChartDataSet && inboundBarChartDataSet.length > 0) {
							for (var i = 0; i < inboundBarChartDataSet.length; i++) {
								channelArr[i] = inboundBarChartDataSet[i].channel;
								var product_volume = inboundBarChartDataSet[i].product_volume;
								// var tempVolume = 0;
								var outboundProductVolume = 0;
								var errorVolume = 0;
								var noErrorVolume = 0;
								for (var pv = 0; pv < product_volume.length; pv++) {
									errorVolume += product_volume[pv].compliance_error_yes;
									noErrorVolume += product_volume[pv].compliance_error_no;
									if (product_volume[pv].product === "return" || product_volume[pv].product === "outbound") {
										if (outboundChannelArr.indexOf(inboundBarChartDataSet[i].channel) === -1) {
											outboundChannelArr.push(inboundBarChartDataSet[i].channel);
										}
										outboundProductVolume += product_volume[pv].volume;
									}
									// if(product_volume[pv].product==="Click and Collect" || product_volume[pv].product==="Inbound") {
									// 	if(inboundChannelArr.indexOf(inboundBarChartDataSet[i].channel) === -1) {
									// 		inboundChannelArr.push(inboundBarChartDataSet[i].channel);
									// 	}
									// 	tempVolume += product_volume[pv].volume;
									// }
								}
								errorVolumeArr[i] = errorVolume;
								noErrorVolumeArr[i] = noErrorVolume;
								// if(tempVolume!==0) {
								// 	volumeArr.push(tempVolume);
								// }
								if (outboundProductVolume !== 0) {
									outboundVolumeArr.push(outboundProductVolume);
								}
							}
						}
						var inboundOutboundDataSet = chartResponse.checkout_inbound_volume;

						if (inboundOutboundDataSet && inboundOutboundDataSet.length > 0) {
							for (var i = 0; i < inboundOutboundDataSet.length; i++) {
								inboundChannelArr[i] = inboundOutboundDataSet[i].channel;
								var product_volume = inboundOutboundDataSet[i].product_volume;
								var tempVolume = 0;
								for (var pv = 0; pv < product_volume.length; pv++) {
									tempVolume += product_volume[pv].volume;
								}
								volumeArr.push(tempVolume);
							}
						}
						var inboundBarChartData = {
							labels: inboundChannelArr,
							datasets: [{
								label: this.get('session.data.authenticated.userDetails.courierName'),
								backgroundColor: '#fcc49d',
								stack: 'Stack 0',
								data: volumeArr
							}]

						};
						var complianceErrorData = {
							labels: channelArr,
							datasets: [{
								label: 'Error',
								backgroundColor: '#fbb77b',
								borderColor: '#fbb77b',
								fill: false,
								data: errorVolumeArr
							}, {
								label: 'No Error',
								backgroundColor: '#53969f',
								borderColor: '#53969f',
								fill: false,
								data: noErrorVolumeArr
							}]

						};
						var outboundBarChartData = {
							labels: outboundChannelArr,
							datasets: [{
								label: 'Increase',
								backgroundColor: '#a3c0c6',
								stack: 'Stack 0',
								data: outboundVolumeArr
							}]

						};

						var rtcChannelArr = [];
						var stateArr = [];
						var stateKeyValueArr = [];

						var rtcDataSetArr = chartResponse.state_volume;
						var stateCount = 0;
						if (rtcDataSetArr && rtcDataSetArr.length > 0) {
							for (var i = 0; i < rtcDataSetArr.length; i++) {
								rtcChannelArr[i] = rtcDataSetArr[i].channel;
								var stateVolumeArr = rtcDataSetArr[i].state_volume;
								for (var s = 0; s < stateVolumeArr.length; s++) {
									var foundIndexVal = stateArr.indexOf(stateVolumeArr[s].state);
									if (foundIndexVal === -1) {
										stateCount++;
										stateArr.push(stateVolumeArr[s].state);
										var tempArr = [];
										if (i === 0) {
											tempArr.push(stateVolumeArr[s].volume);
										} else {
											tempArr[i] = stateVolumeArr[s].volume;
										}
										var tempObj = {
											label: stateVolumeArr[s].state,
											fill: true,
											backgroundColor: colorsArr[stateCount].fill,
											pointBackgroundColor: colorsArr[stateCount].stroke,
											borderColor: colorsArr[stateCount].stroke,
											pointHighlightStroke: colorsArr[stateCount].stroke,
											borderCapStyle: 'butt',
											data: tempArr
										};
										stateKeyValueArr.push(tempObj);
									} else {
										stateKeyValueArr[foundIndexVal]['data'].push(stateVolumeArr[s].volume);
									}
								}
							}
						}
					} //end of if condition
				var inboundBarChartOptions = {
					legend: legendSettings,
					responsive: true,
					scales: {
						xAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Channel',
								fontColor: scaleFontColor
							} }],
						yAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Parcel Count',
								fontColor: scaleFontColor
							}
						}]
					}
				};

				controller.set("inboundBarChartData", inboundBarChartData);
				controller.set("inboundBarChartOptions", inboundBarChartOptions);
				this.set("inboundBarChartBool", true);

				var complianceErrorOptions = {
					legend: legendSettings,
					responsive: true,
					tooltips: {
						mode: 'index',
						intersect: false
					},
					hover: {
						mode: 'nearest',
						intersect: true
					},
					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Channel',
								fontColor: scaleFontColor
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Parcel Count',
								fontColor: scaleFontColor
							}
						}]
					}
				};
				controller.set("complianceErrorData", complianceErrorData);
				controller.set("complianceErrorOptions", complianceErrorOptions);
				this.set("complianceChart", true);

				var outboundBarChartOptions = {
					legend: {
						display: false
					},
					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Channel',
								fontColor: scaleFontColor
							}
						}],
						yAxes: [{
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Parcel Count',
								fontColor: scaleFontColor
							}
						}]
					},
					responsive: true
				};
				controller.set("outboundBarChartData", outboundBarChartData);
				controller.set("outboundBarChartOptions", outboundBarChartOptions);
				this.set('outboundBarChartBool', true);

				var rtcOptions = {
					responsive: true,
					legend: legendSettings,
					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Channel',
								fontColor: scaleFontColor
							}
						}],
						yAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Parcel Count',
								fontColor: scaleFontColor
							}
						}]
					},
					animation: {
						duration: 750
					}
				};

				var rtcDataSet = {
					labels: rtcChannelArr,
					datasets: stateKeyValueArr
				};

				controller.set("rtcDataSet", rtcDataSet);
				controller.set("rtcOptions", rtcOptions);
				this.set('rtcChart', true);

				var lockerParcelData = {
					labels: ['Locker Parcels'],
					datasets: [{
						label: this.get('session.data.authenticated.userDetails.courierName'),
						backgroundColor: '#fcc49d',
						stack: 'Stack 0',
						data: [lockerCountNum]
					}]

				};
				var lockerParcelOptions = {
					legend: legendSettings,
					responsive: true,
					scales: {
						xAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Customer',
								fontColor: scaleFontColor
							} }],
						yAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true
							},
							scaleLabel: {
								display: true,
								labelString: 'Parcel Count',
								fontColor: scaleFontColor
							}
						}]
					}
				};
				controller.set("lockerParcelData", lockerParcelData);
				controller.set("lockerParcelOptions", lockerParcelOptions);
				this.set("showLockerChart", true);

				if (this.get('horizontalBarBool') && this.get('inboundBarChartBool') && this.get('outboundBarChartBool') && this.get('showLockerChart') && this.get('rtcChart') && this.get('complianceChart')) {
					this.get("loadingSpinner").hide();
				}
			}
		}
	});
});