define('ecommerce-portal/shapes/reset-password', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      description: "Password",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 50
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{4,}$/,
        message: 'Password must contain atleast one Capital letter, small letter, number and special character.'
      })]
    },
    confirm_password: {
      description: "Confirm Password",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 50
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{4,}$/,
        message: 'Confirm Password must contain atleast one Capital letter, small letter, number and special character.'
      }), (0, _emberCpValidations.validator)('confirmation', {
        on: 'password',
        message: '{description} do not match'
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    firstname: null,
    lastname: null,
    password: null
  });
});