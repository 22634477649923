define('ecommerce-portal/pods/update/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ecommerce-portal/shapes/ecommerce-courier', 'ecommerce-portal/shapes/carrier-settings'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ecommercePortalShapesEcommerceCourier, _ecommercePortalShapesCarrierSettings) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      controller.get("loadingSpinner").show();

      controller.set("isSavingBool", false);
      controller.set("isClickBillingCheckbox", false);
      controller.set("productMappingList", null);
      controller.set("carrierAccountSettings", _ecommercePortalShapesCarrierSettings['default'].create(_ember['default'].getOwner(controller).ownerInjection(), {}));

      controller.set("webhooksMappingList", null);
      controller.set("billableServiceMapping", null);
      controller.set("reseetPasswordBool", false);
      controller.set("carrier_account", null);
      controller.set("sel_carrier_account_type", null);
      controller.set("selfDataBool", false);
      controller.set("products_list_bool", false);
      controller.set("channel_list_bool", false);
      controller.set("customizedChannel", 0);
      controller.set("showCustomizedNetwork", false);

      this.get('ajax').request('/v1/ecommerceuser/labelservicemasterlist').then(function (response) {
        controller.set("serviceListing", response);
      }, console.log);

      this.get('ajax').request('/v1/ecommerceuser/paymentaccounttypes/' + this.get('session.data.authenticated.hubbedUserId')).then(function (response) {
        controller.set("accountListing", response);
        if (controller.get('carrier_account') === null) {
          for (var i = 0; i < response.length; i++) {
            if (response[i].account_type === "hubbed") {
              controller.set('carrier_account', response[i].id);
              break;
            }
          }
        }
      }, console.log);

      // fetch countries
      this.get('ajax').request('/v1/ecommerceuser/GetMasterTablesList', {
        headers: {
          'Accept': 'application/json',
          'Authorization': "Bearer " + this.get('session.data.authenticated.token')
        }
      }).then(function (response) {
        controller.set("countries", response.result.countries);
        controller.set("timezone_list", response.result.timeZoneList);

        _this.get('ajax').request('v1/ecommerceuser/edit/' + _this.get('session.data.authenticated.hubbedUserId')).then(function (response) {
          var responseData = response.result;
          if (responseData) {
            controller.set("tempPassword", 'Hubbed!1@990');
            var results = responseData;
            var billableServicesArr = [];
            results.billableServiceMapping.map(function (a) {
              billableServicesArr.push(a.billableservice_id);
            });
            results['customername'] = results.company_name;
            results['timezone'] = results.timeZoneId;

            results['contactname'] = results.contact_name;
            results['email'] = results.contact_email;
            results['phone'] = results.contact_phone;
            results['position'] = results.contact_position;

            results['address1'] = results.contact_address;
            results['address2'] = results.contact_address2;
            results['suburb'] = results.contact_suburb;
            results['postcode'] = results.contact_postcode;

            results['state'] = results.contact_state;
            results['country'] = results.contact_country;
            var carrier_partner_str = 'none';
            if (results.carrier_partner && results.carrier_partner !== "") {
              carrier_partner_str = results.carrier_partner;
            }
            results['carrier_partner'] = carrier_partner_str;

            if (results.contact_name === results.billing_contactname && results.contact_position === results.billing_position && results.contact_email === results.billing_email && results.contact_phone === results.billing_phone && results.contact_address === results.billing_address && results.contact_address2 === results.billing_address2 && results.contact_suburb === results.billing_suburb && results.contact_state === results.billing_state && results.contact_postcode === results.billing_postcode && results.contact_country === results.billing_country) {
              controller.set("isBillingOpen", true);
              controller.set('populateBillingBool', true);
            } else {
              controller.set("isBillingOpen", false);
              controller.set('populateBillingBool', false);
              controller.set("isClickBillingCheckbox", true);
            }
            results['billableservice'] = billableServicesArr;

            if (results.ecommerceProductMapping.length > 0) {
              results.ecommerceProductMapping.map(function (a) {
                if (a.enabled === true) {
                  controller.set("products_list_bool", true);
                }
              });
            }
            if (results.ecommerceChannelMapping.length > 0) {
              results.ecommerceChannelMapping.map(function (a) {
                if (a.active === true) {
                  controller.set("channel_list_bool", true);
                }
              });
            }
            if (results.showCustomNetworkRadioButton && results.showCustomNetworkRadioButton === true) {
              controller.set("showCustomizedNetwork", true);
              if (results.isCustomNetwork && results.isCustomNetwork === true) {
                controller.set("customizedChannel", 1);
              }
            }
            controller.set("productMappingList", results.ecommerceProductMapping);
            controller.set("webhooksMappingList", results.webhookEventMapping);
            controller.set("webhooksMappingList", results.webhookEventMapping);
            controller.set("channelMappingList", results.ecommerceChannelMapping);

            controller.set("carrier_account", results.payment_account_type_id);

            if (results.userLabelAccountSettings && results.userLabelAccountSettings !== null) {
              controller.set("selfDataBool", true);
              var carrierSettings = results.userLabelAccountSettings;
              carrierSettings['carrier_partner'] = results.carrier_partner;

              controller.set("sel_carrier_account_type", 'self');
              controller.set("carrierAccountSettings", _ecommercePortalShapesCarrierSettings['default'].create(_ember['default'].getOwner(controller).ownerInjection(), carrierSettings));
            }
            if (results.notification_username && results.notification_url && results.notification_username !== "" && results.notification_url != "") {
              results['notification_password'] = controller.get('tempPassword');
            } else {
              results['notification_password'] = '';
            }

            controller.set("newcourier", _ecommercePortalShapesEcommerceCourier['default'].create(_ember['default'].getOwner(controller).ownerInjection(), results));
            controller.get("loadingSpinner").hide();
          } else {
            _this.get('notify').error('Error : Not able to fetch details, please try again.');
            controller.get("loadingSpinner").hide();
          }
        }, console.log);
      }, console.log);
    }
  });
});