define('ecommerce-portal/pods/customer/view/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service('loading'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.set('productMapping', false);
      controller.set("viewContent", null);
      controller.set('selectedEcomCourier', model.id);
      controller.get("loadingSpinner").show();
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set('showPageSetupUI', false);

      controller.get('ajax').request('/v1/ecommerceuser/edit/' + model.id).then(function (response) {
        if (response && response.result && response.result !== null) {
          controller.set('selectedEcomCourier', response.result.partnerID);
          if (response.result && response.result.ecommerceAccountSettings && response.result.ecommerceAccountSettings !== null && response.result.ecommerceAccountSettings['skinbackground'] && response.result.ecommerceAccountSettings['skinbackground'] !== null) {
            var bgData = response.result.ecommerceAccountSettings['skinbackground'];
            var bgColorBool = false;
            if (bgData.indexOf('#') >= 0) {
              bgColorBool = true;
            }

            response.result['skinBgColorBool'] = bgColorBool;
          }

          var defaultTemp = "default";
          if (response.result.template && (response.result.template !== "" || response.result.template !== null) && response.result.template !== "default") {
            defaultTemp = response.result.template;
          }
          var themeNameStr = defaultTemp;
          if (config.returnPortalTemplate && config.returnPortalTemplate.length > 0) {
            for (var c = 0; c < config.returnPortalTemplate.length; c++) {
              if (config.returnPortalTemplate[c].code === defaultTemp) {
                themeNameStr = config.returnPortalTemplate[c].name;
                break;
              }
            }
          }
          response.result['template'] = defaultTemp;
          response.result['templateName'] = themeNameStr;

          controller.set("channels_list_bool", false);
          if (response.result.ecommerceChannelMapping.length > 0) {
            response.result.ecommerceChannelMapping.map(function (a) {
              if (a.active) {
                controller.set("channels_list_bool", true);
              }
            });
          }

          if (response.result.ecommerceProductMapping && response.result.ecommerceProductMapping.length > 0) {
            response.result.ecommerceProductMapping.map(function (a) {
              if (a.enabled === true) {
                controller.set('productMapping', true);
                if (a.enabled === true && a.self_enabled === true && a.product_name === "Click & Collect") {
                  controller.set('clickCollectPageSetupUI', true);
                } else if (a.self_enabled === false && a.product_name === "Click & Collect") {
                  controller.set('clickCollectPageSetupUI', false);
                }
                if (a.enabled === true && a.self_enabled === true && a.product_name === "Return") {
                  controller.set('returnPageSetupUI', true);
                } else if (a.self_enabled === false && a.product_name === "Return") {
                  controller.set('returnPageSetupUI', false);
                }
                if (controller.get('clickCollectPageSetupUI') === true || controller.get('returnPageSetupUI') === true) {
                  controller.set('showPageSetupUI', true);
                } else {
                  controller.set('showPageSetupUI', false);
                }
              }
            });
          }

          response.result['ecommerceReturnURL'] = config.ecommerceReturnURL;
          controller.set("viewContent", response.result);
        } else {
          controller.get('notify').error('Error encountered while fetching the details.');
          controller.transitionToRoute('customer.list');
        }
        controller.get("loadingSpinner").hide();
      }, console.log);
    }
  });
});