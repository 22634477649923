define('ecommerce-portal/pods/customer/view/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loading: _ember['default'].inject.service('loading'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    actions: {
      backTo: function backTo() {
        this.transitionToRoute('customer.list');
      }
    }
  });
});