define('ecommerce-portal/pods/customer/list/controller', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),

    loading: false,
    page: 1,
    totalPages: 1,
    couriersData: _ember['default'].A([]),
    eUserDeletionDetails: null,

    buildUrl: function buildUrl(page) {
      var url = '/v1/ecommerceuser/GetPartnerCustomers';
      return url;
    },
    actions: {
      ecommerceUserDelete: function ecommerceUserDelete(data) {
        var _this = this;

        this.get("loadingSpinner").show();
        this.get('ajax').request('/v1/ecommerceuser/SoftDeleteUser/' + data.id).then(function () /*result*/{
          _this.get('notify').success(data.companyName + ' is Successfully deleted.');
          _this.set("eUserDeletionDetails", null);
          _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('hide');
          _this.get("loadingSpinner").show();
          _this.send("getPage", _this.get('page'));
        }, function () /*error*/{
          _this.get('notify').error('Could not delete ' + data.companyName + '. Try refreshing this page.');
          _this.get("loadingSpinner").hide();
        });
      },
      deleteChildModalOpen: function deleteChildModalOpen(courierDetails) {
        courierDetails['companyname'] = courierDetails['name'];
        this.set("eUserDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('show');
      },
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);

        this.get('ajax').post(this.buildUrl(page), {
          data: JSON.stringify({
            pageNo: page,
            perPageCustomers: 10,
            partnerId: this.get('session.data.authenticated.hubbedUserId'),
            searchKeyword: this.get('q')
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (responseData) {
          if (responseData && responseData.customers) {
            var result = responseData;
            self.set('page', result.pageNo);
            if (result.pageNo === 1 && result.totalPages === 0) {
              self.set('totalPages', 1);
            } else {
              self.set('totalPages', result.totalPages);
            }
            self.set('couriersData', _ember['default'].A(result.customers));
            self.set("loading", false);
            self.get("loadingSpinner").hide();
          } else {
            self.set("loading", false);
          }
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      changeActiveEcommCourier: function changeActiveEcommCourier(data) {
        var _this2 = this;

        _ember['default'].set(data, "isActive", !data.isActive);
        this.get("loadingSpinner").show();
        this.get('ajax').request('v1/ecommerceuser/isactive?hubbed_user_id=' + data.id + '&status=' + data.isActive).then(function () /*result*/{
          _this2.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this2.get("notify").error("Conflicting in Updation");
          } else {
            _this2.get("notify").error("Unknown error");
          }
          _ember['default'].set(data, "enabled", !data.isActive);
          _this2.get("loadingSpinner").hide();
        });
      },
      search: function search() {
        this.send("getPage", 1);
      },
      orderBy: function orderBy(orderValue, orderAs) {
        this.set('orderBy', orderValue);
        this.set('order', orderAs);
        this.send("getPage", 1);
      },
      deleteModalOpen: function deleteModalOpen(courierDetails) {
        this.set("eUserDeletionDetails", courierDetails);
        _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('show');
      }
    }
  });
});