define('ecommerce-portal/pods/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: _ember['default'].inject.service('session'),

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      //This is to fix link highligting
      this.transitionTo("dashboard");
      // if ((this.get('session.data.authenticated.userDetails.company_name')==='7-Eleven') || (this.get('session.data.authenticated.userDetails.company_name')==='ParcelMate') || (this.get('session.data.authenticated.userDetails.company_name')==='ParcelMates')) {
      // 	this.transitionTo("dashboard-711");
      // } else {
      // 	this.transitionTo("dashboard");
      // }  
    }
  });
});