define('ecommerce-portal/pods/report/index/controller', ['exports', 'ember', 'ember-ajax/errors', 'moment'], function (exports, _ember, _emberAjaxErrors, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    loading: false,

    transactionReportURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', 'startDateFormat', 'endDateFormat', 'selfID', function () {
      var jwt = this.get('session.data.authenticated.token');
      var startDate = this.get("startDateFormat");
      var toDate = this.get("endDateFormat");
      var queryString = 'hubbeduserid=' + this.get('selfID');

      if (startDate !== null) {
        queryString += '&fromdate=' + startDate;
      } else {
        queryString += '&fromdate=';
      }
      if (toDate !== null) {
        queryString += '&todate=' + toDate;
      } else {
        queryString += '&todate=';
      }
      queryString += '&timezoneOffset=' + (0, _moment['default'])().utcOffset() + '&timezone=' + _moment['default'].tz.guess() + '&t=' + this.get('session.data.authenticated.token');
      //return `${this.get("config.apiURL")}/v1/dashboard/downloadreport?reporttype=usertransactions&${queryString}`;
      $.ajax({
        url: this.get("config.apiURL") + '/v1/dashboard/downloadreport?reporttype=usertransactions&' + queryString,
        type: 'GET',
        headers: {
          'Authorization': "Bearer " + this.get('session.data.authenticated.token')
        },
        contentType: "application/json",
        crossDomain: true,
        success: function success(result) {
          //console.log('success', result);
          var a = document.createElement('a');
          var blob = new Blob([result], { type: "octet/stream" });
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = 'transaction_analysis_report.csv';
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: function error(jqXHR, exception) {
          console.log('error', jqXHR);
        }
      });
    }),
    actions: {

      transactionReportURLDownload: function transactionReportURLDownload() {
        var startDate = this.get("startDateFormat");
        var toDate = this.get("endDateFormat");
        var queryString = 'hubbeduserid=' + this.get('selfID');

        if (startDate !== null) {
          queryString += '&fromdate=' + startDate;
        } else {
          queryString += '&fromdate=';
        }

        if (toDate !== null) {
          queryString += '&todate=' + toDate;
        } else {
          queryString += '&todate=';
        }

        queryString += '&timezoneOffset=' + (0, _moment['default'])().utcOffset() + '&timezone=' + _moment['default'].tz.guess();

        // Construct the download URL without setting custom headers
        var downloadUrl = this.get("config.apiURL") + '/v1/dashboard/downloadreport?reporttype=usertransactions&' + queryString;

        // Use jQuery AJAX to manually handle the download
        $.ajax({
          url: downloadUrl,
          type: 'GET',
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.token')
          },
          contentType: "application/json",
          xhrFields: {
            responseType: 'blob' // This is crucial for handling binary data
          },
          success: function success(result) {
            // Create a Blob from the response
            var blob = new Blob([result], { type: 'octet/stream' });

            // Create a link and trigger the download
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'transaction_analysis_report.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(a.href);
          },
          error: function error(jqXHR, exception) {
            console.log('error', jqXHR);
          }
        });
      },

      onChangeDateFrom: function onChangeDateFrom(val, selectedValue) {
        this.set("enableDownloadBool", true);

        if (val === "startDate") {
          this.set('startDateFormat', null);
        }
        if (val === "endDate") {
          this.set('endDateFormat', null);
        }
        if (val === "startDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var startDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('startDateFormat', startDate);
          this.set('startDateTimestamp', (0, _moment['default'])(selectedValue).format("X"));
        } else if (val === "startDate" && (selectedValue === "" || selectedValue === null)) {
          this.set('startDateFormat', null);
          this.set('startDateTimestamp', null);
        }
        if (val === "endDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var endDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('endDateFormat', endDate);
          this.set('endDateTimestamp', (0, _moment['default'])(selectedValue).format("X"));
        } else if (val === "endDate" && (selectedValue === "" || selectedValue === null)) {
          this.set('endDateFormat', null);
          this.set('endDateTimestamp', null);
        }
        if (this.get('startDateTimestamp') > this.get('endDateTimestamp')) {
          this.set("enableDownloadBool", false);
        }
        if (this.get('startDateFormat') === this.get('endDateFormat')) {
          this.set("enableDownloadBool", true);
        }
        if (selectedValue === "" || selectedValue == null) {
          this.set("enableDownloadBool", false);
        }
      }
    }
  });
});