define('ecommerce-portal/pods/components/ecommerce-user-delete/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'ecommerce-user-deletion',
    classNames: ['ecommerce-user-deletion'],

    onHide: function onHide() {
      this.set("eUserDeletionDetails", null);
    },

    actions: {
      confirm: function confirm() {
        this.get("onDelete")(this.get("currentUser"));
      },
      cancel: function cancel() {
        this.set("eUserDeletionDetails", null);
        _ember['default'].$('.ui.modal.ecommerce-user-deletion').modal('hide');
      }
    }
  });
});