define('ecommerce-portal/pods/profile/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      this.set('selectedEcomCourier', params.id);
      return params;
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      controller.get("loadingSpinner").show();
      controller.set('selectedEcomCourier', model.id);
      controller.set('ecommerceCourierDetails', null);
      controller.set("selfDataBool", false);
      controller.set('returnPageSetupUI', false);
      controller.set("products_list_bool", false);

      this.get('ajax').request('v1/ecommerceuser/edit/' + this.get('session.data.authenticated.hubbedUserId')).then(function (response) {
        var responseData = response.result;
        var carrier_partner = "None";
        if (responseData.carrier_partner && responseData.carrier_partner !== null && responseData.carrier_partner !== undefined && responseData.carrier_partner !== "") {
          if (responseData.carrier_partner === "cpl") {
            carrier_partner = "Couriers Please";
          } else if (responseData.carrier_partner === "toll") {
            carrier_partner = "Toll";
          }
        }
        responseData['carrier_partner'] = carrier_partner;
        if (responseData.userLabelAccountSettings && responseData.userLabelAccountSettings !== null) {
          controller.set("selfDataBool", true);
        }
        var defaultTemp = "default";
        if (responseData.template && (responseData.template !== "" || responseData.template !== null) && responseData.template !== "default") {
          defaultTemp = response.result.template;
        }
        var themeNameStr = defaultTemp;
        if (config.returnPortalTemplate && config.returnPortalTemplate.length > 0) {
          for (var c = 0; c < config.returnPortalTemplate.length; c++) {
            if (config.returnPortalTemplate[c].code === defaultTemp) {
              themeNameStr = config.returnPortalTemplate[c].name;
              break;
            }
          }
        }
        responseData['template'] = defaultTemp;
        responseData['templateName'] = themeNameStr;

        var loggedInUserType = _this.get('session.data.authenticated.userType');
        controller.set("channels_list_bool", false);
        if (response.result.ecommerceChannelMapping.length > 0) {
          response.result.ecommerceChannelMapping.map(function (a) {
            if (loggedInUserType === 'partner' && a.active) {
              controller.set("channels_list_bool", true);
            } else if (loggedInUserType === 'customer' && a.self_active === true && a.active) {
              controller.set("channels_list_bool", true);
            }
          });
        }

        if (response.result.ecommerceProductMapping.length > 0) {
          response.result.ecommerceProductMapping.map(function (a) {
            if (loggedInUserType === 'partner' && a.enabled === true) {
              controller.set("products_list_bool", true);
            } else if (loggedInUserType === 'customer' && a.self_enabled === true && a.enabled === true) {
              controller.set("products_list_bool", true);
            }
            if (a.enabled === true && a.self_enabled === true && a.product_name === "Return") {
              controller.set('returnPageSetupUI', true);
            } else if (a.self_enabled === false && a.product_name === "Return") {
              controller.set('returnPageSetupUI', false);
            }
          });
        }
        responseData['ecommerceReturnURL'] = config.ecommerceReturnURL;

        controller.set("ecommerceCourierDetails", responseData);

        controller.get("loadingSpinner").hide();
      }, function () /**xhr**/{
        controller.get("loadingSpinner").hide();
      });
    },
    actions: {
      createDeveloperAcc: function createDeveloperAcc() {
        this.transitionTo('ecommerce.customer', this.get("selectedEcomCourier"));
      }
    }
  });
});