define('ecommerce-portal/router', ['exports', 'ember', 'ecommerce-portal/config/environment'], function (exports, _ember, _ecommercePortalConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _ecommercePortalConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('activate', { path: '/activate/:key' });
    this.route('dashboard');
    this.route('dashboard1');
    this.route('dashboard-711');
    this.route('profile');
    this.route('consignment-tracking');
    this.route('update');
    this.route('manage-profile');

    this.route('reset');
    this.route('forgot-password');
    this.route('customer', function () {
      this.route('view', { path: '/view/:id' });
      this.route('list');
      this.route('new');
      this.route('edit', { path: '/edit/:id' });
    });
    this.route('report', function () {
      this.route('index');
      this.route('transactions');
      this.route('outbound');
      this.route('consignments');
    });
    //this.route('not-found', { path: '/*path' });
  });

  exports['default'] = Router;
});