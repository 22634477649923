define('ecommerce-portal/pods/application/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    actions: {
      signOutConfirm: function signOutConfirm() {
        this.get('session').invalidate();
      },

      openSignOutConfirm: function openSignOutConfirm() {
        _ember['default'].$('.ui.basic.modal.sign-out-confirm').modal('setting', 'closable', false).modal('show');
      },

      toggle: function toggle(id) {
        _ember['default'].$('#' + id).sidebar('setting', 'transition', 'scale down').sidebar('toggle');
      }
    }

  });
});