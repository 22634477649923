define('ecommerce-portal/shapes/carrier-settings', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    carrier_partner: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    account_number: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    account_sysid: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    connote_prefix: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    connote_start: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    connote_end: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_company_prefix: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_extension: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_ai: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    gs1_prefix: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_serial_number_prefix: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_start: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    },
    sscc_end: {
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false })]
    }

  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    carrier_partner: null,
    account_number: null,
    account_sysid: null,
    connote_prefix: null,
    connote_start: null,
    connote_end: null,
    sscc_company_prefix: null,
    sscc_extension: null,
    sscc_ai: null,
    gs1_prefix: null,
    sscc_serial_number_prefix: null,
    sscc_start: null,
    sscc_end: null
  });
});