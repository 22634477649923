define('ecommerce-portal/pods/report/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    setupController: function setupController(controller) {
      var startDateStr = new Date();
      var endDateStr = new Date();

      controller.set("endDateFormat", moment(endDateStr).format("DD/MM/YYYY"));
      controller.set("startDateFormat", moment(startDateStr).format("DD/MM/YYYY"));
      controller.set("startDate", startDateStr);
      controller.set("endDate", endDateStr);
      controller.set("startDateTimestamp", moment(startDateStr).format("X"));
      controller.set("endDateTimestamp", moment(endDateStr).format("X"));
      controller.set("enableDownloadBool", true);

      controller.set("selfID", this.get('session.data.authenticated.hubbedUserId'));
    }
  });
});