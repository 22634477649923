define('ecommerce-portal/pods/activate/route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ecommerce-portal/shapes/activation'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ecommercePortalShapesActivation) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        notify: _ember['default'].inject.service('notify'),
        ajax: _ember['default'].inject.service(),
        session: _ember['default'].inject.service('session'),

        model: function model(params) {
            return params;
        },
        setupController: function setupController(controller, model) {
            controller.set('activation_key', model.key);
            controller.set('activationValidation', _ecommercePortalShapesActivation['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
        }
    });
});