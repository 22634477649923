define('ecommerce-portal/pods/dashboard/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		config: _ember['default'].inject.service('config'),
		notify: _ember['default'].inject.service('notify'),
		ajax: _ember['default'].inject.service(),
		session: _ember['default'].inject.service('session'),
		loadingSpinner: _ember['default'].inject.service('loading'),

		setupController: function setupController(controller) {
			controller.get("loadingSpinner").show();
			// if ((this.get('session.data.authenticated.userDetails.company_name')==='7-Eleven') || (this.get('session.data.authenticated.userDetails.partnerName')==='7-Eleven')) {
			//  	this.transitionTo("dashboard-711");
			//  }
			controller.set('lockerCountNum', 0);
			controller.set('rtcCountNum', 0);
			controller.set('inboundCheckoutCountNum', 0);
			controller.set('outboundCountNum', 0);
			controller.set('checkInInboundCountNum', 0);

			this.get('ajax').post('v1/dashboard/userchartreport').then(function (response) {
				if (response.responseCode === "200" || response.responseCode === 200) {
					var chartResponse = response.result;
					controller.send("drawCharts", chartResponse);
				} else {
					controller.get("loadingSpinner").hide();
				}
			})['catch'](function (error) {
				controller.get("loadingSpinner").hide();
			});
		}
	});
});