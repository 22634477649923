define('ecommerce-portal/shapes/ecommerce-courier', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    customername: {
      description: "Partner Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    timezone: [(0, _emberCpValidations.validator)('presence', true)],
    logo: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      // type : 'url',
      regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
      message: 'Invalid URL'
    })],
    contactname: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    billing_contactname: {
      description: "Name",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    position: {
      description: "Position",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: false }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z][a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    billing_email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    billing_phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 12
      })]
    },
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    address1: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_address: {
      description: "Address Line",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_postcode: {
      description: "Postcode",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    billing_state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('length', {
        max: 3
      })]
    },
    billing_country: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[^\s].*/,
        message: 'Invalid characters'
      })]
    },
    notification_url: {
      description: "Notification URL",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /\b((http|https|HTTP|HTTPS):\/\/?)[^\s()<>]+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        message: 'Invalid URL'
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var username = model.get('notification_username');

        if (value !== undefined && value !== "" && value !== null) {
          if (username === undefined || username === "" || username === null) {
            return 'Please enter user name to enable webhook notifications';
          }
        }

        return true;
      }, {
        dependentKeys: ['model.notification_url', 'model.notification_username', 'model.notification_password']
      })]
    },
    notification_username: {
      description: "User Name",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notification_password = model.get('notification_password');
        var notification_url = model.get('notification_url');

        if (value !== undefined && value !== "" && value !== null) {
          if (notification_password === undefined || notification_password === "" || notification_password === null) {
            return 'Please enter password to enable webhook notifications';
          }

          if (notification_url === undefined || notification_url === "" || notification_url === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notification_username', 'model.notification_url', 'model.notification_password']
      })]
    },
    notification_password: {
      description: "Notification Password",
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)(function (value, options, model /*, attribute*/) {
        var notification_url = model.get('notification_url');

        if (value !== undefined && value !== "" && value !== null) {
          if (notification_url === undefined || notification_url === "" || notification_url === null) {
            return 'Please enter notification url to enable webhook notifications';
          }
        }
        return true;
      }, {
        dependentKeys: ['model.notification_password', 'model.notification_url', 'model.notification_username']
      })]
    }
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    contactname: null,
    billing_contactname: null,
    phone: null, billing_phone: null,
    email: null, billing_email: null,
    address1: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    billing_address: null,
    billing_suburb: null,
    billing_postcode: null,
    billing_state: null,
    billing_country: null
  });
});