define('ecommerce-portal/pods/activate/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),
    activationValidation: null,
    actions: {
      activate_account: function activate_account() {
        var _this = this;

        var self = this;
        this.get('ajax').post('v1/account/ActivateHubbedUserAccount', {
          headers: {
            'Accept': 'application/json'
          },
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            activationkey: this.get('activation_key'),
            confirmpassword: this.get('activationValidation.confirm_password'),
            password: this.get('activationValidation.password')
          })
        }).then(function (response) {
          if (response.responseCode && (response.responseCode === "200" || response.responseCode === 200)) {
            _this.set('activationValidation', '');
            _this.transitionToRoute('login');
          } else {
            _this.set('activationValidation.password', '');
            _this.set('activationValidation.confirm_password', '');
            _this.set('errorMessage', response.responseMessage);
          }
        }, function (xhr) {
          if (xhr.errors[0] && xhr.errors[0].status === "0") {
            _this.set('errorMessage', "Connection Failed");
          } else if (xhr.errors[0] && xhr.errors[0].status === "401") {
            _this.set('errorMessage', "Invalid Credentials");
          } else if (xhr.errors[0] && xhr.errors[0].status === "429") {
            _this.set('errorMessage', "You have been locked out of the system after too many failed login attempts. This will reset in 30 minutes.");
          } else {
            _this.set('errorMessage', "System Error");
          }
        })['catch'](function (reason) {
          _this.set('errorMessage', "Connection Failed");
        });
      }
    }
  });
});