define('ecommerce-portal/shapes/login', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 50
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{4,}$/,
      message: 'Password must contain atleast one capital letter, small letter, number and special character.'
    })]
  });
  exports['default'] = _ember['default'].Object.extend(Validations, {
    email: null,
    password: null
  });
});