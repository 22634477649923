define('ecommerce-portal/pods/forgot-password/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),

    email: "",
    successMessage: false,

    actions: {
      closeMessage: function closeMessage() {
        this.set('errorMessage', '');
        this.set('successMessage', '');
      },
      forgotPassword: function forgotPassword() {
        var _this = this;

        this.set("isSavingBool", true);
        var self = this;
        self.set('errorMessage', '');
        self.set('successMessage', '');

        this.get('ajax').post('v1/account/SendForgotPasswordEmail', {
          data: JSON.stringify({
            email: this.get("email")
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (data) {
          _this.set("isSavingBool", false);
          if (data.responseCode === "200" || data.responseCode === 200) {
            self.set('successMessage', true);
            self.set('email', '');
          } else {
            self.set('errorMessage', data.responseMessage);
          }
        })['catch'](function (error) {
          _this.set("isSavingBool", false);
          if (error && error.errors) {
            var errorMsg = error.errors.map(function (e) {
              return e.detail;
            });
            if (errorMsg[0] && errorMsg[0].message) {
              self.set('errorMessage', errorMsg[0].message);
            } else if (errorMsg[0] && errorMsg[0].result && errorMsg[0].result.length > 0) {
              self.set('errorMessage', errorMsg[0].result[0].description);
            } else {
              self.set('errorMessage', 'Email doesn\'t exists');
            }
          } else {
            self.set('errorMessage', '');
          }
        });
      }
    }
  });
});