define('ecommerce-portal/pods/reset/route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ecommerce-portal/shapes/reset-password'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ecommercePortalShapesResetPassword) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
        config: _ember['default'].inject.service('config'),
        notify: _ember['default'].inject.service('notify'),
        ajax: _ember['default'].inject.service(),
        session: _ember['default'].inject.service('session'),

        model: function model(params) {
            return params;
        },
        setupController: function setupController(controller, model) {
            controller.set('isLoadingBool', true);

            var currentUrl = window.location.href;
            var getKeyRegex = new RegExp('[?&]key=([^&#]*)', 'i');
            var string = getKeyRegex.exec(currentUrl);
            var keyValue = string ? string[1] : null;

            var getTimestampRegex = new RegExp('[?&]d=([^&#]*)', 'i');
            var timestampStr = getTimestampRegex.exec(currentUrl);
            var timestampValue = timestampStr ? timestampStr[1] : null;

            this.get('ajax').request('v1/account/IsResetPasswordLinkExpired?encodedUtcTimestamp=' + timestampValue).then(function (response) {
                if (response && (response.responseCode === "200" || response.responseCode === 200)) {
                    controller.set('validToken', true);
                    controller.set('encryptedData', keyValue);
                    controller.set('resetValidation', _ecommercePortalShapesResetPassword['default'].create(_ember['default'].getOwner(controller).ownerInjection()));
                } else {
                    controller.set('validToken', false);
                }
                controller.set('isLoadingBool', false);
            }, function () /**xhr**/{});
        }
    });
});