define('ecommerce-portal/pods/customer/edit/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ecommerce-portal/shapes/developer-account', 'ecommerce-portal/shapes/carrier-settings'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ecommercePortalShapesDeveloperAccount, _ecommercePortalShapesCarrierSettings) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      controller.get("loadingSpinner").show();
      controller.set("showThemeOptionBool", false);

      controller.set("isSavingBool", false);
      controller.set("developerAccount", null);
      controller.set("isClickBillingCheckbox", false);
      controller.set('showPageSetupUI', false);
      controller.set("isBillingOpen", false);
      controller.set('populateBillingBool', false);
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set('notValidReturnLocation', false);
      controller.set("updateLocationBool", false);
      controller.set("reseetPasswordBool", false);
      controller.set("selfDataBool", false);
      controller.set("products_list_bool", false);
      controller.set("defaultTemplateBool", true);

      controller.set("carrierAccountSettings", _ecommercePortalShapesCarrierSettings['default'].create(_ember['default'].getOwner(controller).ownerInjection(), {}));

      this.get('ajax').request('/v1/ecommerceuser/labelservicemasterlist').then(function (response) {
        controller.set("serviceListing", response);
      }, console.log);

      this.get('ajax').request('/v1/ecommerceuser/paymentaccounttypes/' + model.id).then(function (response) {
        controller.set("accountListing", response);
        if (controller.get('carrier_account') === null) {
          for (var i = 0; i < response.length; i++) {
            if (response[i].account_type === "hubbed") {
              controller.set('carrier_account', response[i].id);
              break;
            }
          }
        }
      }, console.log);

      this.get('ajax').request('/v1/ecommerceuser/GetMasterTablesList').then(function (response) {
        controller.set("countries", response.result.countries);
        controller.set("timezone_list", response.result.timeZoneList);

        controller.get('ajax').request('/V1/ecommerceuser/edit/' + model.id).then(function (response) {
          if (response && response.result) {
            var results = response.result;
            controller.set('selectedEcomCourier', results.partnerID);
            controller.set("tempPassword", 'Hubbed!1@990');
            controller.set("apiKeyValue", results.apiKey);
            controller.set("productMappingList", results.ecommerceProductMapping);
            controller.set("webhookEventList", results.webhookEventMapping);
            controller.set("channelMappingList", results.ecommerceChannelMapping);
            controller.set("channels_list_bool", false);
            if (results.ecommerceChannelMapping.length > 0) {
              results.ecommerceChannelMapping.map(function (a) {
                if (a.active) {
                  controller.set("channels_list_bool", true);
                }
              });
            }
            controller.set("carrier_account", null);
            controller.set("sel_carrier_account_type", null);

            if (results.parentTemplate && (results.parentTemplate !== "" || results.parentTemplate !== null) && results.parentTemplate !== "default") {
              // controller.set("showThemeOptionBool", true);
            }

            if (results.contact_name === results.billing_contactname && results.contact_position === results.billing_position && results.contact_email === results.billing_email && results.contact_phone === results.billing_phone && results.contact_address === results.billing_address && results.contact_address2 === results.billing_address2 && results.contact_suburb === results.billing_suburb && results.contact_state === results.billing_state && results.contact_postcode === results.billing_postcode && results.contact_country === results.billing_country) {
              controller.set("isBillingOpen", true);
              controller.set('populateBillingBool', true);
            } else {
              controller.set("isBillingOpen", false);
              controller.set('populateBillingBool', false);
              controller.set("isClickBillingCheckbox", true);
            }

            controller.set('returnLocations', results.ecommerceLocationMapping);
            var selectedSkinBgMethod = 'Image';
            controller.set("skinBgImageBool", true);
            controller.set("skinBgColorBool", false);

            results['enable_payment'] = false;
            if (results.payment_rate_type && results.payment_rate_type === 1) {
              results['enable_payment'] = true;
            }
            results['sale_rate'] = 0;
            results['buy_rate'] = 0;
            results['additional_charge'] = 0;

            if (results && results.fixedRates && results.fixedRates !== null) {
              results['sale_rate'] = results.fixedRates['sale_rate'];
              results['buy_rate'] = results.fixedRates['buy_rate'];
              results['additional_charge'] = results.fixedRates['additional_charge'];
            }
            if (results && results.ecommerceAccountSettings) {
              results['website'] = results.ecommerceAccountSettings['website'];
              results['logo'] = results.ecommerceAccountSettings['logo'];
              results['terms_of_service'] = results.ecommerceAccountSettings['termsofserviceurl'];
              results['returnpolicy'] = results.ecommerceAccountSettings['returnpolicy'];
              results['parcel_info'] = results.ecommerceAccountSettings['parcelinstructionsurl'];
              results['about_us'] = results.ecommerceAccountSettings['aboutus'];
            }
            if (results.template && (results.template !== "" || results.template !== null) && results.template !== "default") {
              controller.set('defaultTemplateBool', false);
            } else {
              results['template'] = "default";
            }
            if (results && results.ecommerceAccountSettings && results.ecommerceAccountSettings && results.ecommerceAccountSettings['skinbackground'] && results.ecommerceAccountSettings['skinbackground'] !== null) {
              var bgData = results.ecommerceAccountSettings['skinbackground'];
              if (bgData.indexOf('#') >= 0) {
                selectedSkinBgMethod = 'Color';
                controller.set("skinBgImageBool", false);
                controller.set("skinBgColorBool", true);
              }
            }
            var channelsArr = [];
            if (response.result && response.result.ecommerceChannelMapping && response.result.ecommerceChannelMapping.length > 0) {
              for (var i = 0; i < response.result.ecommerceChannelMapping.length; i++) {
                channelsArr.push(response.result.ecommerceChannelMapping[i].channel_id);
              }
            }
            results['skinBgMethod'] = selectedSkinBgMethod;
            results['timezoneId'] = results.timeZoneId;
            if (results.notification_username && results.notification_url && results.notification_username !== "" && results.notification_url != "") {
              results['notification_password'] = controller.get("tempPassword");
            } else {
              results['notification_password'] = '';
            }
            results['channel_ids'] = channelsArr;

            controller.set("carrier_account", results.payment_account_type_id);

            if (results.userLabelAccountSettings !== null) {
              var carrierSettings = results.userLabelAccountSettings;

              carrierSettings['carrier_partner'] = results.carrier_partner;
              controller.set("sel_carrier_account_type", 'self');
              controller.set("selfDataBool", true);

              controller.set("carrierAccountSettings", _ecommercePortalShapesCarrierSettings['default'].create(_ember['default'].getOwner(controller).ownerInjection(), carrierSettings));
            }

            controller.set("developerAccount", _ecommercePortalShapesDeveloperAccount['default'].create(_ember['default'].getOwner(controller).ownerInjection(), results));
            controller.set("developerAccount.customNetwork", 0);

            if (results.isCustomNetwork && results.isCustomNetwork === true) {
              controller.set("developerAccount.customNetwork", 1);
            }

            var productsDataSet = [];
            if (response.result.ecommerceProductMapping.length > 0) {

              response.result.ecommerceProductMapping.map(function (a) {
                if (a.enabled) {
                  controller.set("products_list_bool", true);
                  productsDataSet.push(a);
                }

                if (a.self_enabled === true && a.product_name === "Click & Collect") {
                  controller.set('clickCollectPageSetupUI', true);
                } else if (a.self_enabled === false && a.product_name === "Click & Collect") {
                  controller.set('clickCollectPageSetupUI', false);
                }
                if (a.self_enabled === true && a.enabled === true && a.product_name === "Return") {
                  controller.set('returnPageSetupUI', true);
                } else if (a.self_enabled === false && a.product_name === "Return") {
                  controller.set('returnPageSetupUI', false);
                }
                if (controller.get('returnPageSetupUI') === true || controller.get('clickCollectPageSetupUI') === true) {
                  controller.set('showPageSetupUI', true);
                } else {
                  controller.set('showPageSetupUI', false);
                }
              });
            }
            controller.set("productMappingList", productsDataSet);
          } else {
            controller.get('notify').error('Error encountered while fetching the details.');
            controller.transitionToRoute('customer.list');
          }
          controller.get("loadingSpinner").hide();
        }, console.log);
      }, console.log);
    }
  });
});