define('ecommerce-portal/pods/login/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    email: "",
    password: "",

    actions: {
      closeMessage: function closeMessage() {
        this.set('errorMessage', "");
      },
      login: function login() {
        var self = this;
        var email = self.get('loginValidation.email');
        var password = self.get('loginValidation.password');

        this.get('session').authenticate('authenticator:jwt', email, password)['catch'](function (reason) {
          self.set('errorMessage', reason);
        });
      }
    }
  });
});