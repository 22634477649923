define('ecommerce-portal/pods/consignment-tracking/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    loading: false,
    loadingSpinner: _ember['default'].inject.service('loading'),
    consignmentNumbers: '',
    barcodes: '',
    foundConsignments: _ember['default'].A([]),
    page: 1,
    totalPages: 1,
    searchText: '',
    searchBy: '',
    buildUrl: function buildUrl() {
      var url = '/v1/TrackAndTrace/SearchConsignments';
      return url;
    },
    actions: {
      updateValue: function updateValue(newValue) {
        this.set('searchBy', newValue);
        //console.log(this.get("searchBy"));
      },
      toggleInfo: function toggleInfo(data) {
        if (data.toogleDetails) {
          _ember['default'].set(data, "toogleDetails", !data.toogleDetails);
        } else {
          _ember['default'].set(data, "toogleDetails", true);
        }
      },
      getPage: function getPage(page) {
        var self = this;
        var searchSuccessfully = true,
            errorMsgStr = '';
        /*if(this.get('consignmentNumbers')!=='' || this.get('barcodes')!==''){
          var barcodeArr = this.get('barcodes').split(",");
          var consignmentNumberArr = this.get('consignmentNumbers').split(",");
          if(consignmentNumberArr.length>=6 || barcodeArr.length>=6) {
            searchSuccessfully = false;
            errorMsgStr = 'You can only search upto 5 consignment numbers or article barcode';
          }
        } else {
          searchSuccessfully = false;
          errorMsgStr = 'Please enter consignment number or article barcode .';
        }*/

        var con = '';
        var barcode = '';

        if (this.get("searchBy") !== '') {
          // check length
          var searchValArr = this.get('searchText').split(",");
          if (searchValArr.length >= 6) {
            searchSuccessfully = false;
            errorMsgStr = 'You can only search upto 5 values at a time';
          }
          if (this.get("searchBy") === "consignment") {
            con = this.get('searchText');
          } else if (this.get("searchBy") === "barcode") {
            barcode = this.get('searchText');
          }
        } else {
          searchSuccessfully = false;
          errorMsgStr = 'Please select one search by option and enter value for search';
        }

        if (searchSuccessfully) {
          self.set("loading", true);
          this.get("loadingSpinner").show();

          this.get('ajax').post(this.buildUrl(page), {
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + this.get('session.data.authenticated.token')
            },
            data: JSON.stringify({
              pageNo: page,
              perPageRecords: 20,
              consignmentNumbers: con,
              barcodes: barcode
            }),
            contentType: 'application/json; charset=utf-8',
            processData: false,
            crossDomain: true
          }).then(function (result) {
            self.set('page', result.pageNo);
            self.set('totalPages', result.totalPages);
            self.set('foundConsignments', _ember['default'].A(result.data));
            self.set("loading", false);
            self.get("loadingSpinner").hide();
          }, function (error) {
            console.log(error);
          });
        } else {
          this.get('notify').error(errorMsgStr);
        }
      },
      applyFilter: function applyFilter() {
        this.send("getPage", 1);
      }
    }
  });
});