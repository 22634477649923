define('ecommerce-portal/pods/report/transactions/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    setupController: function setupController(controller) {
      controller.set("isSelfBool", true);
      controller.set("customersList", null);
      controller.set("selfID", this.get('session.data.authenticated.hubbedUserId'));
      controller.set("selectedUserId", this.get('session.data.authenticated.hubbedUserId'));

      controller.set("endDateFormat", null);
      controller.set("startDateFormat", null);
      controller.set("startDate", null);
      controller.set("endDate", null);
      controller.set("q", '');

      controller.send("getPage", 1);

      controller.get('ajax').post('/v1/ecommerceuser/GetPartnerCustomers', {
        data: JSON.stringify({
          pageNo: 1,
          perPageCustomers: 100,
          partnerId: this.get('session.data.authenticated.hubbedUserId')
        }),
        contentType: 'application/json; charset=utf-8',
        crossDomain: true
      }).then(function (responseData) {
        if (responseData && responseData.customers) {
          controller.set("customersList", responseData.customers);
        }
      });
    }
  });
});