define("ecommerce-portal/pods/components/file-input/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["file-input"],

    label: _ember["default"].computed("fileName", function () {
      if (this.get("fileName")) {
        var split = this.get("fileName").split("\\");
        return split[split.length - 1];
      }
      return null;
    }),

    fileNameChanged: _ember["default"].observer("fileName", function () {
      this.get("onSelectFile")(document.getElementById(this.elementId + "-input").files[0]);
    })

  });
});