define('ecommerce-portal/pods/report/transactions/controller', ['exports', 'ember', 'ember-ajax/errors', 'moment'], function (exports, _ember, _emberAjaxErrors, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    loading: false,
    page: 1,
    totalPages: 1,
    startDate: null,
    endDate: null,

    startDateFormat: null,
    endDateFormat: null,
    buildUrl: function buildUrl(page) {
      var url = '/v1/dashboard/TransactionReports';
      return url;
    },
    actions: {
      /**filterByDate() {
        if(this.get("startDate") && this.get("startDate")!=="" && this.get("startDate")!==null){
          let startDate = moment(this.get("startDate")).format("DD/MM/YYYY");
          this.set('startDateFormat', startDate);
        }
        if(this.get("endDate") && this.get("endDate")!=="" && this.get("endDate")!==null){
          let endDate = moment(this.get("endDate")).format("DD/MM/YYYY");
          this.set('endDateFormat', endDate);
        }      
        this.send("getPage", 1);
      },**/
      search: function search() {
        this.send("getPage", 1);
      },
      filterDataByUser: function filterDataByUser(selectedUserId) {
        if (this.get('selfID') === selectedUserId) {
          this.set('isSelfBool', true);
        } else {
          this.set('isSelfBool', false);
        }
        this.set("selectedUserId", selectedUserId);
        this.send("getPage", 1);
      },
      onChangeDateFrom: function onChangeDateFrom(val, selectedValue) {
        this.set('dateFrom', selectedValue);
        if (val === "startDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var startDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('startDateFormat', startDate);
        }
        if (val === "endDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var endDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('endDateFormat', endDate);
        }
        this.send("getPage", 1);
      },
      getPage: function getPage(page) {
        var self = this;
        self.set("loading", true);
        var postDataObj = {
          "pageno": page,
          "perpage": 20,
          "hubbeduserid": this.get('selectedUserId'),
          "isself": self.get('isSelfBool')
        };
        if (this.get('q') !== '' && this.get('q') !== undefined) {
          postDataObj['consignmentnumber'] = this.get("q").trim();
        }
        if (this.get('startDateFormat') !== '' && this.get('startDateFormat') !== null) {
          postDataObj['fromdate'] = this.get("startDateFormat");
        }
        if (this.get('endDateFormat') !== '' && this.get('endDateFormat') !== null) {
          postDataObj['todate'] = this.get("endDateFormat");
        }

        this.get('ajax').post(this.buildUrl(page), {
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var res = response.result;
          if (res === null) {
            self.set('page', 1);
            self.set('totalPages', 1);
            self.set('reportData', []);
          } else {
            if (res.pageno === 1 && res.totalPages === 0) {
              self.set('totalPages', 1);
            } else if (res.totalPages) {
              self.set('totalPages', parseInt(res.totalPages));
            } else {
              self.set('totalPages', 1);
            }
            if (res.pageno) {
              self.set('page', parseInt(res.pageno));
            } else {
              self.set('page', 1);
            }

            self.set('reportData', _ember['default'].A(res.query));
          }
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set("loading", false);
        });
      },
      changeActive: function changeActive(data) {
        var _this = this;

        _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
        this.get("loadingSpinner").show();
        var postDataObj = {
          "courierCode": data.courier_Code,
          "email": data.agedParcelReport_Email,
          "sendReport": data.agedParcelReport_SendReport
        };
        if (data.agedParcelReport_Id && data.agedParcelReport_Id !== 0) {
          postDataObj['id'] = data.agedParcelReport_Id;
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'AgedParcel/SaveAgedParcelReportSetting', {
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function (dl) {
          if (dl.responseCode !== 200 && dl.responseCode !== "200" && dl.responseMessage) {
            _this.get("notify").error(dl.responseMessage);
            _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
          }
          _this.get("loadingSpinner").hide();
        }, function (error) {
          if ((0, _emberAjaxErrors.isConflictError)(error)) {
            _this.get("notify").error("Conflicting in Updation");
          } else {
            _this.get("notify").error("Unknown error");
          }
          _ember['default'].set(data, "agedParcelReport_SendReport", !data.agedParcelReport_SendReport);
          _this.get("loadingSpinner").hide();
        });
      },
      editOpen: function editOpen(code) {
        this.set("edit", accountValidation.create(_ember['default'].getOwner(this).ownerInjection(), {
          id: code.agedParcelReport_Id,
          email: code.agedParcelReport_Email,
          name: code.courier_Name,
          code: code.courier_Code,
          sendReport: code.agedParcelReport_SendReport && code.agedParcelReport_SendReport !== null ? code.agedParcelReport_SendReport : false
        }));
        _ember['default'].$('.ui.edit-aged-code.modal').modal('show');
      },
      closeEdit: function closeEdit() {
        this.set("edit", null);
        _ember['default'].$('.ui.edit-aged-code.modal').modal('hide');
      },
      saveEdit: function saveEdit(edit) {
        var _this2 = this;

        this.get("loadingSpinner").show();
        var postDataObj = {
          "courierCode": edit.code,
          "email": edit.email,
          "sendReport": edit.sendReport
        };
        if (edit.id && edit.id !== 0) {
          postDataObj['id'] = edit.id;
        }
        this.get('ajax').post(this.get("config.carrierApiURL") + 'AgedParcel/SaveAgedParcelReportSetting', {
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*dl*/{
          _this2.send("getPage", _this2.get('page'));
          _this2.get("loadingSpinner").hide();
          _ember['default'].$('.ui.edit-aged-code.modal').modal('hide');
          _this2.get("notify").success('Successfully updated.');
        }, function () /*error*/{
          _this2.get("loadingSpinner").hide();
          _this2.get("notify").error('Error edited.');
        });
      }
    }
  });
});