define('ecommerce-portal/pods/reset/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    ajax: _ember['default'].inject.service(),
    resetValidation: null,
    queryParams: 'key',
    actions: {
      closeMessage: function closeMessage() {
        this.set('errorMessage', "");
      },
      activate_account: function activate_account() {
        var _this = this;

        var self = this;
        this.set('errorMessage', "");
        this.get('ajax').post('v1/account/UpdatePassword', {
          data: JSON.stringify({
            encryptedEmail: this.get('encryptedData'),
            newPassword: this.get('resetValidation.password')
          }),
          contentType: 'application/json; charset=utf-8',
          crossDomain: true
        }).then(function (response) {
          if (response.responseCode && (response.responseCode === "200" || response.responseCode === 200)) {
            _this.set('resetValidation', '');
            _this.get('notify').success('You have successfully reset the password, please try login now!');
            _this.transitionToRoute('login');
          } else {
            _this.set('resetValidation', '');
            _this.set('errorMessage', response.responseMessage);
          }
        }, function (xhr) {
          if (xhr.errors[0] && xhr.errors[0].status === "0") {
            _this.set('errorMessage', "Connection Failed");
          } else if (xhr.errors[0] && xhr.errors[0].status === "401") {
            _this.set('errorMessage', "Invalid Credentials");
          } else if (xhr.errors[0] && xhr.errors[0].status === "429") {
            _this.set('errorMessage', "You have been locked out of the system after too many failed login attempts. This will reset in 30 minutes.");
          } else {
            _this.set('errorMessage', "System Error");
          }
        })['catch'](function (reason) {
          _this.set('errorMessage', "Connection Failed");
        });
      }
    }
  });
});