define('ecommerce-portal/helpers/sort-array', ['exports', 'ember'], function (exports, _ember) {
  exports.sortArrayHelper = sortArrayHelper;

  function sortArrayHelper(params) {
    var statesArr = params[0];
    statesArr.sort(function (a, b) {
      return new Date(b.dateTime) - new Date(a.dateTime);
    });
    return statesArr;
  }

  exports['default'] = _ember['default'].Helper.helper(sortArrayHelper);
});