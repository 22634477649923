define('ecommerce-portal/pods/customer/list/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service(),
    notify: _ember['default'].inject.service(),

    setupController: function setupController(controller) {
      this.get('ajax').request('v1/ecommerceuser/edit/' + this.get('session.data.authenticated.hubbedUserId')).then(function (response) {
        var responseData = response.result;
        if (responseData.active) {
          controller.set('activeParent', true);
        } else {
          controller.set('activeParent', false);
        }
        controller.send("getPage", 1);
      });
    },
    actions: {
      createUnknwonAcc: function createUnknwonAcc(data) {
        if (data && data !== null && data !== undefined) {
          this.transitionTo('ecommerce.customer', data);
        } else {
          this.get('notify').error('This option is not avaiable right now, please try after sometime.');
        }
      }
    }
  });
});