define("ecommerce-portal/helpers/courier-helper", ["exports", "ember"], function (exports, _ember) {
  exports.courierHelper = courierHelper;

  function courierHelper(params) {
    var courier = params[0];
    switch (courier) {
      case "dhl":
        return "DHL Express";
      case "cti":
        return "CTI";
      case "cpl":
        return "Couriers Please";
      case "toll":
        return "Toll";
      case "dsc":
        return "DHL Supply Chain";
      default:
        return courier;
    }
  }

  exports["default"] = _ember["default"].Helper.helper(courierHelper);
});