define('ecommerce-portal/shapes/email-address', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email eg. abc@domain.com'
    })]
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    email: null
  });
});