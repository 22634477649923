define('ecommerce-portal/pods/report/outbound/controller', ['exports', 'ember', 'ember-ajax/errors', 'moment'], function (exports, _ember, _emberAjaxErrors, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    loadingSpinner: _ember['default'].inject.service('loading'),
    notify: _ember['default'].inject.service(),
    loading: false,
    page: 1,
    totalPages: 1,
    totalRecords: 0,
    startDate: null,
    endDate: null,

    startDateFormat: null,
    endDateFormat: null,
    buildUrl: function buildUrl(page) {
      var url = '/v1/dashboard/getuseroutboundconsignments';
      return url;
    },
    downloadInboundReport: _ember['default'].computed('session.data.authenticated', 'config.apiURL', 'startDateFormat', 'endDateFormat', 'page', 'totalRecords', 'selectedUserId', 'isSelfBool', 'q', 'orderBy', 'order', function () {
      var jwt = this.get('session.data.authenticated.token');
      var startDate = this.get("startDateFormat");
      var toDate = this.get("endDateFormat");
      var queryString = 'pageno=' + this.get('page') + '&perpage=' + this.get('totalRecords') + '&hubbeduserid=' + this.get('selectedUserId') + '&isself=' + this.get('isSelfBool');
      if (this.get('q') !== '' && this.get('q') !== undefined) {
        queryString += '&consignmentnumber=' + this.get("q").trim();
      } else {
        queryString += '&consignmentnumber=';
      }
      if (startDate !== null) {
        queryString += '&fromdate=' + startDate;
      } else {
        queryString += '&fromdate=';
      }
      if (toDate !== null) {
        queryString += '&todate=' + toDate;
      } else {
        queryString += '&todate=';
      }
      if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
        queryString += '&orderBy=' + this.get("orderBy");
      } else {
        queryString += '&orderBy=_CreatedOn';
      }
      if (this.get('order') !== '' && this.get('order') !== undefined) {
        queryString += '&orderDir=' + this.get("order");
      } else {
        queryString += '&orderDir=desc';
      }
      queryString += '&timezoneOffset=' + (0, _moment['default'])().utcOffset() + '&timezone=' + _moment['default'].tz.guess() + '&t=' + this.get('session.data.authenticated.token');
      // return `${this.get("config.apiURL")}/v1/dashboard/downloadreport?reporttype=outbound&${queryString}`;
    }),
    actions: {

      downloadOutboundReport: function downloadOutboundReport() {
        var jwt = this.get('session.data.authenticated.token');
        var startDate = this.get("startDateFormat");
        var toDate = this.get("endDateFormat");
        var queryString = 'pageno=' + this.get('page') + '&perpage=' + this.get('totalRecords') + '&hubbeduserid=' + this.get('selectedUserId') + '&isself=' + this.get('isSelfBool');
        if (this.get('q') !== '' && this.get('q') !== undefined) {
          queryString += '&consignmentnumber=' + this.get("q").trim();
        } else {
          queryString += '&consignmentnumber=';
        }
        if (startDate !== null) {
          queryString += '&fromdate=' + startDate;
        } else {
          queryString += '&fromdate=';
        }
        if (toDate !== null) {
          queryString += '&todate=' + toDate;
        } else {
          queryString += '&todate=';
        }
        if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
          queryString += '&orderBy=' + this.get("orderBy");
        } else {
          queryString += '&orderBy=_CreatedOn';
        }
        if (this.get('order') !== '' && this.get('order') !== undefined) {
          queryString += '&orderDir=' + this.get("order");
        } else {
          queryString += '&orderDir=desc';
        }
        queryString += '&timezoneOffset=' + (0, _moment['default'])().utcOffset() + '&timezone=' + _moment['default'].tz.guess();

        $.ajax({
          url: this.get("config.apiURL") + '/v1/dashboard/downloadreport?reporttype=outbound&' + queryString,
          type: 'GET',
          headers: {
            'Authorization': "Bearer " + this.get('session.data.authenticated.token')
          },
          contentType: "application/json",
          crossDomain: true,
          success: function success(result) {
            var a = document.createElement('a');
            var blob = new Blob([result], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'outbound.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          },
          error: function error(jqXHR, exception) {
            console.log('error', jqXHR);
          }
        });
      },

      onChangeDateFrom: function onChangeDateFrom(val, selectedValue) {
        if (val === "startDate") {
          this.set('startDateFormat', null);
        }
        if (val === "endDate") {
          this.set('endDateFormat', null);
        }
        if (val === "startDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var startDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('startDateFormat', startDate);
        }
        if (val === "endDate" && selectedValue && selectedValue !== "" && selectedValue !== null) {
          var endDate = (0, _moment['default'])(selectedValue).format("DD/MM/YYYY");
          this.set('endDateFormat', endDate);
        }
        this.send("getPage", 1);
      },
      search: function search() {
        this.send("getPage", 1);
      },
      filterDataByUser: function filterDataByUser(selectedUserId) {
        if (this.get('selfID') === selectedUserId) {
          // this.set('isSelfBool', true); //removed for now
          this.set('isSelfBool', false);
        } else {
          this.set('isSelfBool', false);
        }
        this.set("selectedUserId", selectedUserId);
        this.send("getPage", 1);
      },
      orderBy: function orderBy(orderValue, orderAs) {
        $(".con_asc").hide();
        $(".con_desc").show();

        $("." + orderValue + "_" + orderAs).hide();
        if (orderAs === "asc") {
          $("." + orderValue + "_desc").show();
        } else {
          $("." + orderValue + "_asc").show();
        }
        this.set('orderBy', orderValue);
        this.set('order', orderAs);
        this.send("getPage", this.get('page'));
      },
      getPage: function getPage(page) {
        var _this = this;

        var self = this;
        this.set('allowDownloadBool', false);
        var timezoneOffset = (0, _moment['default'])().utcOffset();

        self.set('page', page);
        self.set("loading", true);
        var postDataObj = {
          "pageno": page,
          "perpage": 20,
          "hubbeduserid": this.get('selectedUserId'),
          "isself": self.get('isSelfBool'),
          "timezone": _moment['default'].tz.guess(),
          "timezoneOffset": timezoneOffset
        };
        if (this.get('q') !== '' && this.get('q') !== undefined) {
          postDataObj['consignmentnumber'] = this.get("q").trim();
        }
        if (this.get('startDateFormat') !== '' && this.get('startDateFormat') !== null) {
          postDataObj['fromdate'] = this.get("startDateFormat");
        }
        if (this.get('endDateFormat') !== '' && this.get('endDateFormat') !== null) {
          postDataObj['todate'] = this.get("endDateFormat");
        }
        if (this.get('orderBy') !== '' && this.get('orderBy') !== undefined) {
          postDataObj['orderBy'] = this.get("orderBy");
        } else {
          postDataObj['orderBy'] = '_CreatedOn';
        }
        if (this.get('order') !== '' && this.get('order') !== undefined) {
          postDataObj['orderDir'] = this.get("order");
        } else {
          postDataObj['orderDir'] = 'desc';
        }

        this.get('ajax').post(this.buildUrl(page), {
          data: JSON.stringify(postDataObj),
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var res = response.result;
          if (res === null) {
            self.set('page', 1);
            self.set('totalPages', 1);
            self.set('reportData', []);
          } else {
            _this.set('allowDownloadBool', true);
            if (response.pageno === 1 && response.totalPages === 0) {
              self.set('totalPages', 1);
            } else if (response.totalPages) {
              self.set('totalPages', parseInt(response.totalPages));
            } else {
              self.set('totalPages', 1);
            }
            if (response.pageno) {
              self.set('page', parseInt(response.pageno));
            } else {
              self.set('page', 1);
            }
            self.set('totalRecords', response.totalRecords);
            self.set('reportData', _ember['default'].A(res));
          }
          self.set("loading", false);
          self.get("loadingSpinner").hide();
        }, function () /*error*/{
          self.set("loading", false);
        });
      }
    }
  });
});