define('ecommerce-portal/pods/customer/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ecommerce-portal/shapes/developer-account'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ecommercePortalShapesDeveloperAccount) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    config: _ember['default'].inject.service('config'),
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      controller.get("loadingSpinner").show();
      controller.set("defaultTemplateBool", true);
      controller.set("showThemeOptionBool", false);

      controller.set("updateLocationBool", false);
      controller.set("skinBgImageBool", true);
      controller.set("skinBgColorBool", false);
      controller.set('isBillingOpen', true);
      controller.set('isSavingBool', false);
      controller.set('notValidReturnLocation', false);
      controller.set('showPageSetupUI', false);
      controller.set('clickCollectPageSetupUI', false);
      controller.set('returnPageSetupUI', false);
      controller.set("products_list_bool", false);
      controller.set("admin_channels_list_bool", false);
      controller.set("partner_channels_list_bool", false);

      controller.set('webhookEventList', null);
      controller.set('returnLocations', []);
      controller.set('developerAccount', null);
      controller.set('developerAccount', _ecommercePortalShapesDeveloperAccount['default'].create(_ember['default'].getOwner(controller).ownerInjection()));

      controller.set('developerAccount.ecommercecourierid', model.id);
      controller.set('developerAccount.template', "default");
      controller.set("developerAccount.customNetwork", 0);

      controller.set('backgroundcolor', null);
      controller.set('fontcolor', null);
      controller.set('buttoncolor', null);
      controller.set('headertextcolor', null);
      controller.set('stepsbackgroundcolor', null);

      this.get('ajax').request('/v1/ecommerceuser/edit/' + this.get('session.data.authenticated.hubbedUserId')).then(function (responseData) {
        if (responseData && responseData.result && responseData.result.active) {
          var response = responseData.result;
          controller.set("selectedEcomCourier", _this.get('session.data.authenticated.hubbedUserId'));
          controller.set("selectedEcomCourierName", response.company_name);

          if (response.template && (response.template !== "" || response.template !== null) && response.template !== "default") {
            controller.set('defaultTemplateBool', false);
            controller.set('developerAccount.template', response.template);
            // controller.set("showThemeOptionBool", true);
          }
          if (response.isCustomNetwork && response.isCustomNetwork === true) {
            controller.set("developerAccount.customNetwork", 1);
          }
          controller.set("webhookEventList", response.webhookEventMapping);
          if (response.ecommerceChannelMapping && response.ecommerceChannelMapping.length > 0) {
            var dataSet = [],
                partnerDataSet = [];
            var data = response.ecommerceChannelMapping;
            for (var i = 0; i < data.length; i++) {
              if (data[i].active) {
                controller.set("admin_channels_list_bool", true);
                dataSet.push(data[i]);
              }
            }
            if (dataSet.length > 0) {
              for (var j = 0; j < dataSet.length; j++) {
                if (dataSet[j].self_active) {
                  controller.set("partner_channels_list_bool", true);
                  partnerDataSet.push(dataSet[j]);
                }
              }
            }
            controller.set("channel_list", partnerDataSet);
          }
          if (response.ecommerceProductMapping && response.ecommerceProductMapping.length > 0) {
            var dataSet = [];
            var data = response.ecommerceProductMapping;
            var countProducts = 0;
            for (var i = 0; i < data.length; i++) {
              if (data[i].enabled) {
                dataSet.push(data[i]);
                if (data[i].product_name === "Click & Collect" && data[i].enabled) {
                  controller.set("clickCollectPageSetupUI", true);
                }
                if (data[i].product_name === "Return" && data[i].enabled) {
                  controller.set("returnPageSetupUI", true);
                }
                countProducts++;
              }
            }
            controller.set("products_list", dataSet);

            if (countProducts < 1) {
              controller.set("products_list_bool", false);
            } else {
              controller.set("products_list_bool", true);
            }

            if (controller.get('returnPageSetupUI') === true || controller.get('clickCollectPageSetupUI') === true) {
              controller.set('showPageSetupUI', true);
            } else {
              controller.set('showPageSetupUI', false);
            }
          }
          controller.get("loadingSpinner").hide();
        } else {
          _this.transitionTo('customer.list');
          _this.get('notify').error("You don't have permissions to Add a Customer.");
          controller.get("loadingSpinner").hide();
        }
      }, console.log);

      this.get('ajax').request('/v1/ecommerceuser/GetMasterTablesList').then(function (response) {
        controller.set("countries", response.result.countries);
        controller.set("timezone_list", response.result.timeZoneList);

        if (response && response.result && response.result.roles) {
          for (var i = 0; i <= response.result.roles.length; i++) {
            if (response.result.roles[i].rolename === "EcommerceCustomer") {
              controller.set('role_id', response.result.roles[i].id);
              break;
            }
          }
        }
      }, console.log);
    }
  });
});